import {useQuery} from '@tanstack/react-query';
import {getToday} from 'helpers/dates';
import {useClient} from 'context/auth-context';

const useGetHolidays = (currencies: string[]) => {
    const client = useClient();
    const queryParams: string[] = [];
    const after = getToday();

    queryParams.push(`date[after]=${after}`);
    currencies.forEach(curr => {
        if (curr) {
            queryParams.push(`currency[]=${curr}`);
        }
    });

    return useQuery({
        queryKey: ['holidays', {...currencies, after}],
        queryFn: () =>
            client(`/web_api/holidays?${queryParams.join('&')}`, {}).then(
                response => response.map((holiday: any) => holiday.date),
            ),
        enabled: !!currencies.length,
    });
};

export {useGetHolidays};
