import StepTitle from 'components/pages/OnboardingPage/components/StepTitle';
import Form, {FormOnSubmit} from 'components/ui/Form/Form/Form';

import {IDefaultStepProps} from 'components/pages/OnboardingPage/useOnboarding';
import NextStepButton from 'components/pages/OnboardingPage/components/NextStepButton';
import {useUpdateBusinessPartnerOnboarding} from 'api/business-partner';
import {
    findOnboardingStepByNumber,
    steps,
} from 'components/pages/OnboardingPage/onboardingSteps';
import {handleTypedFormError} from 'helpers/handleFormError';
import CheckBoxGroup from 'components/ui/Form/OnboardingCheckBoxGroup/OnboardingCheckBoxGroup';
import InfoPopover from 'components/ui/InfoPopover/InfoPopover';
import {
    ReasonForAmnisFormValues,
    reasonForAmnisValidationSchema,
} from 'form/validation/businessPartner';
import {ReasonForAmnis} from 'types/api';
import {LegalType} from 'types/onboarding';
import styles from './Steps.module.scss';

const ReasonForAmnisStep = (props: IDefaultStepProps) => {
    const {
        onSuccess,
        currentStep,
        activeBusinessPartner,
        setActiveBusinessPartner,
        legalType,
    } = props;
    const {mutate: updateBusinessPartner, isPending: isLoading} =
        useUpdateBusinessPartnerOnboarding();

    const handleSubmit: FormOnSubmit<ReasonForAmnisFormValues> = (
        data,
        setError,
    ) => {
        updateBusinessPartner(
            {
                gwgInfo: {
                    ...data.gwgInfo,
                    // TODO - fix after yup upgrade
                    reasonForAmnis:
                        // @ts-ignore
                        data.gwgInfo.reasonForAmnis as ReasonForAmnis[],
                    id: activeBusinessPartner.gwgInfo?.id,
                },
                businessPartnerId: activeBusinessPartner['@id'],
                step: findOnboardingStepByNumber(currentStep)?.type,
            },
            {
                onError: (errorResponse: any) =>
                    handleTypedFormError(errorResponse, setError, data),
                onSuccess: response => {
                    setActiveBusinessPartner(response);
                    const newDisabled = steps.reduce<number[]>((acc, curr) => {
                        if (curr.disabled?.(response)) {
                            acc.push(curr.number);
                        }

                        return acc;
                    }, []);
                    onSuccess({overWriteDisabled: newDisabled});
                },
            },
        );
    };

    return (
        <>
            <StepTitle title="Purpose of your amnis account" />
            <Form<ReasonForAmnisFormValues>
                onSubmit={handleSubmit}
                className={styles.form}
                defaultValues={{
                    gwgInfo: {
                        reasonForAmnis:
                            activeBusinessPartner.gwgInfo?.reasonForAmnis || [],
                    },
                }}
                validationSchema={reasonForAmnisValidationSchema}
            >
                <CheckBoxGroup
                    name="gwgInfo.reasonForAmnis"
                    options={[
                        {
                            label: (
                                <span className={styles.checkboxLabel}>
                                    Currency exchange&nbsp;
                                    <InfoPopover
                                        content={
                                            legalType === LegalType.COMPANY ? (
                                                <>
                                                    <ul>
                                                        <li>
                                                            Spot exchange
                                                            transactions.
                                                        </li>
                                                        <li>
                                                            Forward exchange
                                                            transactions for
                                                            hedging purposes.
                                                        </li>
                                                    </ul>
                                                </>
                                            ) : (
                                                'Spot exchange transactions'
                                            )
                                        }
                                    />
                                </span>
                            ),
                            value: 'fx',
                        },
                        {
                            label: 'Debit card',
                            value: 'debit_card',
                        },
                        {
                            label: (
                                <span className={styles.checkboxLabel}>
                                    Incoming payments&nbsp;
                                    <InfoPopover content="The money you receive from customers / third parties." />
                                </span>
                            ),
                            value: 'incoming_payment',
                        },
                        {
                            label: (
                                <span className={styles.checkboxLabel}>
                                    Outgoing payments&nbsp;
                                    <InfoPopover content="The money you pay to your own bank account or to third parties (e.g. supplier bill, operational expenses)." />
                                </span>
                            ),
                            value: 'outgoing_payment',
                        },
                    ]}
                    label="What is the purpose of your amnis account?"
                    type="checkbox"
                    variant="dark"
                    size="large"
                />
                <NextStepButton loading={isLoading} />
            </Form>
        </>
    );
};

export default ReasonForAmnisStep;
