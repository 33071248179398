import {useMutation} from '@tanstack/react-query';

import {useClient} from 'context/auth-context';
import {IToken} from 'types/api';

const useGetMercureToken = () => {
    const client = useClient();

    return useMutation<IToken, unknown, string>({
        mutationFn: token =>
            client('/web_api/mercure/token', {token, method: 'GET'}),
    });
};

export {useGetMercureToken};
