import Form from 'components/ui/Form/Form/Form';

import {
    ICrpBpListItem,
    useGetBusinessPartnerRequiredDocuments,
    useUploadBusinessPartnerDocument,
} from 'api/business-partner';
import FileUploadButton from 'components/ui/Form/FileUploadButton/FileUploadButton';
import useFileUploadStatuses, {
    isNewFileUploadStatus,
} from 'components/ui/Form/FileUploadButton/useFileUploadStatuses';
import {
    BusinessPartner,
    BusinessPartnerRequiredDocument,
    PaymentCustomer,
} from 'types/api';
import {useUploadCrpDocument} from 'api/crp';
import {translate} from 'helpers/localize';
import classNames from 'classnames';
import {REQUIRED_DOCUMENTS} from 'constants/cache';
import InfoPopover from 'components/ui/InfoPopover/InfoPopover';
import {LegalType} from 'types/onboarding';
import {SOURCE_OF_FUNDS} from 'constants/common';
import NextStepButton from '../components/NextStepButton';
import StepTitle from '../components/StepTitle';
import {IDefaultStepProps} from '../useOnboarding';

import styles from './Steps.module.scss';
import {useQueryClient} from '@tanstack/react-query';

const getRequiredDocumentText = ({
    requiredDocument,
    legalType,
    paymentCustomer,
    crpBp,
    businessPartner,
}: {
    requiredDocument: BusinessPartnerRequiredDocument;
    legalType: LegalType | null;
    paymentCustomer?: PaymentCustomer | null;
    crpBp?: ICrpBpListItem | null;
    businessPartner: BusinessPartner;
}): {label: string; info: React.ReactNode} => {
    const sourceOfFunds = legalType
        ? translate(
              SOURCE_OF_FUNDS[legalType]?.find(
                  s => s['@id'] === businessPartner.gwgInfo?.sourceOfFunds,
              )?.label,
          )
        : businessPartner.gwgInfo?.sourceOfFunds;

    switch (requiredDocument.category) {
        case 'id_document':
            return {
                label: translate('ID for %{firstName} %{lastName}', {
                    firstName: crpBp?.crp.firstName,
                    lastName: crpBp?.crp.lastName,
                }),
                info: "Document serving as proof of identity, including examples like a driver's license, passport, or national ID card.",
            };
        case 'utility_bill':
            return {
                label: translate('Utility bill for %{firstName} %{lastName}', {
                    firstName: crpBp?.crp.firstName,
                    lastName: crpBp?.crp.lastName,
                }),
                info: 'A recent electricity, water, or phone/internet bill, or alternatively, a tax or other official government bill, with the personal name and address visible (should not be older than 6 months).',
            };
        case 'company_group':
            return {
                label: translate('Entity structure as file'),
                info: 'Document providing insight into the structure of the company group, such as an organizational chart, details about parent companies, subsidiaries, and legal affiliations.',
            };
        case 'edd_evidence_inheritance':
            return {
                label: translate('Evidence of inheritance'),
                info: 'Inheritance certificates, wills, or legal documents related to inherited assets.',
            };
        case 'edd_evidence_employment':
            return {
                label: translate('Evidence of employment'),
                info: 'Examples of employment evidence include pay stubs, employment contracts, or a letter of employment.',
            };
        case 'incoming_payment_customer':
            return {
                label: translate('Invoice or contract for %{name}', {
                    name: paymentCustomer?.name,
                }),
                info: 'Bank statements or payment receipts showing payments received from customers, as well as contracts or agreements related to such payments.',
            };
        case 'outgoing_payment_customer':
            return {
                label: translate('Invoice or contract for %{name}', {
                    name: paymentCustomer?.name,
                }),
                info: 'Bank statements or payment receipts showing payments made to suppliers or other third parties, as well as contracts or agreements related to such payments.',
            };
        case 'proof_of_ownership':
            return {
                label: translate('Proof of company ownership'),
                info: 'A document serving as proof of ownership for the respective asset or property. Examples include business registration certificates, extracts from commercial registries or corporate share certificates.',
            };
        case 'private_source_of_funds':
            return {
                label: translate('Proof of source of funds'),
                info: (
                    <span>
                        Document providing proof of funds from &quot;
                        <var data-var="sourceOfFunds">{sourceOfFunds}</var>
                        &quot;. The address, amount and date/time stamp should
                        be visible on the document.
                    </span>
                ),
            };
        case 'investment_evidence':
            return {
                label: translate('Evidence of investment'),
                info: 'Investment account statements, stock certificates, or other records of investment holdings.',
            };
        default:
            return {
                label: (
                    requiredDocument as any as BusinessPartnerRequiredDocument
                ).category,
                info: '',
            };
    }
};

const getPaymentCustomerForRequiredDocument = (
    requiredDocument: BusinessPartnerRequiredDocument,
    businessPartner: BusinessPartner,
): PaymentCustomer | null => {
    if (
        requiredDocument.category !== 'incoming_payment_customer' &&
        requiredDocument.category !== 'outgoing_payment_customer'
    )
        return null;

    if (requiredDocument.category === 'incoming_payment_customer') {
        return businessPartner.gwgInfo?.incomingPaymentCustomers.find(
            c => c.uniqueId === requiredDocument.uniqueId,
        )!;
    }
    return businessPartner.gwgInfo?.outgoingPaymentCustomers.find(
        c => c.uniqueId === requiredDocument.uniqueId,
    )!;
};

const getCrpBpForRequiredDocument = (
    requiredDocument: BusinessPartnerRequiredDocument,
    crpBpList: ICrpBpListItem[],
) => {
    if (
        (requiredDocument.category !== 'utility_bill' &&
            requiredDocument.category !== 'id_document') ||
        !crpBpList?.length
    )
        return null;

    return crpBpList.find(
        crpBp => +crpBp.crp['@id'].split('/').pop()! === requiredDocument.crp,
    );
};

const BusinessPartnerDocumentsUpload = (props: IDefaultStepProps) => {
    const {activeBusinessPartner, onSuccess, crpList, legalType} = props;
    const {data} = useGetBusinessPartnerRequiredDocuments(
        activeBusinessPartner,
    );
    const {updateStatus, uploadStatuses, removeStatus} =
        useFileUploadStatuses();
    const {mutate: uploadBpDocument} = useUploadBusinessPartnerDocument();
    const {mutate: uploadCrpDocument} = useUploadCrpDocument();
    const queryClient = useQueryClient();

    const handleFileUpload = (
        files: FileList | null,
        requiredDocument: BusinessPartnerRequiredDocument,
    ) => {
        if (!files) return;
        const file = files[0];
        if (
            !uploadStatuses.find(
                f =>
                    f.name === file.name &&
                    isNewFileUploadStatus(f) &&
                    f.document === file,
            )
        ) {
            const callbacks = {
                onSuccess: () => {
                    removeStatus(file);
                    queryClient.invalidateQueries({
                        queryKey: [REQUIRED_DOCUMENTS],
                    });
                },
                onError: (errorResponse: any) => {
                    updateStatus({
                        document: file,
                        status: 'error',
                        name: file.name,
                        category: requiredDocument.category,
                        errorMessage:
                            errorResponse['hydra:description'] ||
                            'Something went wrong',
                    });
                },
            };

            updateStatus({
                document: file,
                status: 'loading',
                name: file.name,
            });
            if (
                requiredDocument.category === 'id_document' ||
                requiredDocument.category === 'utility_bill'
            ) {
                const crpBp = crpList?.find(
                    c =>
                        +c.crp['@id'].split('/').pop()! ===
                        requiredDocument.crp,
                );

                if (!crpBp) {
                    throw new Error(
                        `CRP with id ${requiredDocument.crp} not found`,
                    );
                }

                uploadCrpDocument(
                    {
                        crpId: crpBp.crp['@id'],
                        description: '', // TODO
                        files: Array.from(files),
                        ...requiredDocument,
                    },
                    callbacks,
                );
                return;
            }
            uploadBpDocument(
                {
                    activeBpId: activeBusinessPartner['@id'],
                    files: Array.from(files),
                    type: 'onboarding',
                    ...requiredDocument,
                },
                callbacks,
            );
        }
    };

    return (
        <div className={styles.form}>
            <StepTitle title="Required documents" />

            {!data?.length ? (
                <div>
                    <p>All required documents are uploaded</p>
                </div>
            ) : (
                <>
                    <p>
                        Please upload the requested files (.pdf/.png/.jpg) in
                        their designated fields.
                    </p>
                    <Form onSubmit={() => false}>
                        <div
                            className={classNames(
                                styles.documentRow,
                                styles.first,
                            )}
                        >
                            <p data-notranslate>
                                {translate('%{count} requested file(s)', {
                                    count: data?.length,
                                })}
                            </p>
                            <p>File status</p>
                        </div>
                        {data?.map(requiredDocument => {
                            const paymentCustomer =
                                getPaymentCustomerForRequiredDocument(
                                    requiredDocument,
                                    activeBusinessPartner,
                                );

                            const crpBp = getCrpBpForRequiredDocument(
                                requiredDocument,
                                crpList,
                            );

                            return (
                                <div
                                    className={styles.documentRow}
                                    key={JSON.stringify(requiredDocument)}
                                >
                                    <span
                                        className={styles.documentLabel}
                                        data-notranslate
                                    >
                                        {
                                            getRequiredDocumentText({
                                                requiredDocument,
                                                legalType,
                                                paymentCustomer,
                                                crpBp,
                                                businessPartner:
                                                    activeBusinessPartner,
                                            })?.label
                                        }
                                        <InfoPopover
                                            content={
                                                getRequiredDocumentText({
                                                    requiredDocument,
                                                    legalType,
                                                    paymentCustomer,
                                                    crpBp,
                                                    businessPartner:
                                                        activeBusinessPartner,
                                                })?.info
                                            }
                                        />
                                    </span>
                                    <FileUploadButton
                                        name={JSON.stringify(requiredDocument)}
                                        containerClassname={styles.inputField}
                                        hideHelperText
                                        uploadStatuses={uploadStatuses}
                                        updateStatus={updateStatus}
                                        removeStatus={removeStatus}
                                        onChange={e =>
                                            handleFileUpload(
                                                e.target.files,
                                                requiredDocument,
                                            )
                                        }
                                    />
                                </div>
                            );
                        })}
                    </Form>
                </>
            )}
            <NextStepButton
                loading={false}
                onClick={() => onSuccess()}
                disabled={
                    (data?.length && uploadStatuses?.length < data?.length) ||
                    uploadStatuses.some(st => st.status !== 'uploaded')
                }
            />
        </div>
    );
};

export default BusinessPartnerDocumentsUpload;
