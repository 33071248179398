import React, {useEffect, useRef} from 'react';
import {useHistory} from 'react-router';
import {PATH} from 'constants/common';
import {OnboardingPageType} from 'types/onboarding';
import {getItemFromStorage, removeItemFromStorage} from 'helpers/localStorage';
import {CONTRACT_NOT_NEEDED_KEY} from 'components/pages/OnboardingPage/steps/ContractOverview';
import {IDefaultStepProps} from '../useOnboarding';
import illustration from '../components/illustrations/4.svg';
import StepTitle from '../components/StepTitle';
import NextStepButton from '../components/NextStepButton';

import styles from './Steps.module.scss';

const FinishOnboarding = (props: IDefaultStepProps) => {
    const {inReview, setOnboardingPageType} = props;
    const history = useHistory();
    const wasReview = useRef<boolean>(false);

    useEffect(() => {
        setOnboardingPageType?.(OnboardingPageType.BLUE);

        return () => setOnboardingPageType?.(OnboardingPageType.WHITE);
    }, [setOnboardingPageType]);

    useEffect(() => {
        const prevLocation = history.location.pathname;
        const unlisten = history.listen(location => {
            if (location.pathname !== prevLocation) {
                removeItemFromStorage(CONTRACT_NOT_NEEDED_KEY);
                wasReview.current = false;
            }
        });

        if (
            inReview ||
            getItemFromStorage(CONTRACT_NOT_NEEDED_KEY) === 'true'
        ) {
            wasReview.current = true;
        }

        return () => unlisten();
    }, [history, inReview]);

    return (
        <div className={styles.form}>
            <div className={styles.centered}>
                <img src={illustration} />
            </div>
            <StepTitle
                title={
                    <h2>
                        Done! <br />
                        &nbsp;
                        {wasReview.current
                            ? 'Thank you for your time!'
                            : 'Welcome to Amnis!'}
                    </h2>
                }
            />
            <NextStepButton
                label="Select your price plan"
                onClick={() => history.push(PATH.PRICING)}
                className={styles.bluePageButton}
                containerClassName={styles.bluePageButtonContainer}
            />
            <NextStepButton
                label="Start for free - upgrade anytime"
                onClick={() => history.push(PATH.DASHBOARD)}
                containerClassName={styles.bluePageButtonContainer}
            />
        </div>
    );
};

export default FinishOnboarding;
