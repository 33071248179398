import React, {
    createContext,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import {useLocation} from 'react-router';
import {LocationDescriptor} from 'history';
import classNames from 'classnames';

import {useBusinessPartner} from 'context/business-partner-context';
import Header from 'components/layout/Header/Header';
import Sidebar from 'components/layout/Sidebar/Sidebar';
import {PATH} from 'constants/common';
import {isIframe} from 'helpers/common';

import styles from './SidebarLayout.module.sass';

export interface IBackPath {
    path: LocationDescriptor<any> | string;
    label: string;
}

export type HeaderContext = {
    backPath: IBackPath | null;
    setBackPath: (params: IBackPath | null) => void;
};

const HeaderContext = createContext<HeaderContext | null>(null);
HeaderContext.displayName = 'HeaderContext';

const SidebarLayout = ({children}: {children: React.ReactNode}) => {
    const [sidebarVisible, setSidebarVisible] = React.useState(false);
    const sidebarContainer = useRef<HTMLDivElement>(null);
    const hamburgerContainer = useRef();
    const location = useLocation();
    const {activeBusinessPartner} = useBusinessPartner();
    const onlyCardUser = activeBusinessPartner?.permission?.onlyCardUser;

    const [backPath, setBackPath] = useState<IBackPath | null>(null);

    useEffect(() => {
        const handlePageClick = (e: any) => {
            if (sidebarContainer && sidebarContainer.current) {
                const target = sidebarContainer.current.contains(e.target);
                const targetHamburger = hamburgerContainer.current === e.target;

                if (sidebarVisible && !target && !targetHamburger) {
                    setSidebarVisible(false);
                }
            }
        };

        document.addEventListener('mousedown', handlePageClick);
        return () => document.removeEventListener('mousedown', handlePageClick);
    });

    const handleHamburgerClick = (e: any) => {
        setSidebarVisible(!sidebarVisible);
    };

    if (
        location.pathname === PATH.PRICING ||
        location.pathname.includes(PATH.ONBOARDING_OVERVIEW)
    ) {
        return (
            <div className={styles.l}>
                <div className={styles.pageContainerFull}>
                    <main className={styles.mainFull}>
                        <HeaderContext.Provider value={{backPath, setBackPath}}>
                            {isIframe() ? null : (
                                <Header
                                    ref={hamburgerContainer}
                                    onHamburgerClick={handleHamburgerClick}
                                    pricing
                                />
                            )}
                            <div className={styles.content}>{children}</div>
                        </HeaderContext.Provider>
                    </main>
                </div>
            </div>
        );
    }

    return (
        <div className={styles.l}>
            <div
                className={classNames(
                    styles.pageContainer,
                    onlyCardUser ? styles.pageContainerOnlyCardUser : null,
                )}
            >
                {!onlyCardUser ? (
                    <div
                        ref={sidebarContainer}
                        className={classNames(styles.sidebar, {
                            [styles.visible]: sidebarVisible,
                        })}
                    >
                        <Sidebar
                            handleCloseSidebar={() => setSidebarVisible(false)}
                        />
                    </div>
                ) : null}

                <main className={styles.main}>
                    <HeaderContext.Provider value={{backPath, setBackPath}}>
                        <Header
                            ref={hamburgerContainer}
                            onHamburgerClick={handleHamburgerClick}
                        />
                        <div className={styles.content}>{children}</div>
                    </HeaderContext.Provider>
                </main>
            </div>
        </div>
    );
};

const useHeader = () => {
    const context = useContext(HeaderContext);

    if (context === null) {
        throw new Error(`useHeader must be used within a HeaderProvider`);
    }
    return context;
};

export default SidebarLayout;
export {useHeader};
