interface IProps {
    description?: React.ReactNode;
    /**
     * @deprecated
     */
    className?: string;
    children: React.ReactNode;
}

const PageTitle = ({children, description}: IProps) => (
    <div>
        <h4>{children}</h4>
        {description ? <p>{description}</p> : null}
    </div>
);

export default PageTitle;
